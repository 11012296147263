<template>
  <component
    :is="wrapper"
    class="relative grid text-gray-500 hover:text-black before:scale-90 hover:before:scale-100 place-content-center w-[--width] outline-none focus-visible:before:ring-2 before:ring-gray-100 h-[--height] before:content-[''] before:transition before:-inset-2 shrink-0 before:absolute hover:before:bg-gray-50 before:rounded-full"
    :style="{
      '--width': `${iconSize}px`,
      '--height': `${iconSize}px`,
    }"
    :to="to"
    type="button"
  >
    <app-icon class="relative" :icon="icon" :size="iconSize ?? 20" />
    <span class="sr-only">{{ label }}</span>
  </component>
</template>

<script lang="ts" setup>
import type { TranslateResult } from "vue-i18n";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { RouteLocationRaw } from "#vue-router";

const properties = withDefaults(
  defineProps<{
    icon: string;
    label: TranslateResult;
    to?: RouteLocationRaw;
    iconSize?: number;
  }>(),
  {
    to: undefined,
    iconSize: 20,
  },
);

const wrapper = computed(() =>
  properties.to ? resolveComponent("nuxt-link") : "button",
);
</script>
